export const ITEMS_PER_PAGE = 15;
export const NB_QUALITY_STARS = 4;
export const MIN_SIZE_QUALITY_STARS = 5;
export const MAX_SIZE_QUALITY_STARS = 10;

export const SEARCH_SELECTOR_OPTIONS = {
  NO_SORT: "AUCUN TRI",
  YEAR: "TRI PAR ANNEE",
  NAME: "TRI PAR NOM",
  PRICE: "TRI PAR PRIX",
};

export const BOTTLE_QUALITIES = {
  VERY_GOOD: "très bien",
  GOOD: "bien",
  BAD: "assez bien",
  VERY_BAD: "mauvaise état",
};
